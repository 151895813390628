import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['otherForm', 'ownForm', 'toggleButton']

    initialize() {
        this.toggleReady = true
        this.ownButton = this.ownFormTarget.querySelector("button[type='submit']")
        this.otherButton = this.otherFormTarget.querySelector("button[type='submit']")
        this.submitOnEvent = this.submitOnEvent.bind(this)
    }

    connect() {
        document.addEventListener('submit-event', this.submitOnEvent)
        this.observer = new MutationObserver(() => {
            this._syncDisabled()
        })
        this.observer.observe(this.ownButton, {attributes: true, attributeFilter: ['disabled']});

        console.log("submit_other_form is connected")
    }

    disconnect() {
        this.observer.disconnect()
        document.removeEventListener('submit-event', this.submitOnEvent)
    }

    submitOnEvent(event) {
        const fieldFileIDElement = this.element.querySelector('[data-controller="direct-upload"] [data-direct-upload-target="fieldFileID"]')
        const fileNameElement = this.element.querySelector('[data-controller="direct-upload"] [data-direct-upload-target="fileName"]')
        if (fieldFileIDElement) fieldFileIDElement.value = event.detail.mediaFileId
        if (fileNameElement) fileNameElement.innerText = event.detail.mediaFileName
        if (this.otherFormTarget) {
            Rails.fire(this.otherFormTarget, 'submit')
            if (this.otherFormTarget.dataset.remote !== 'true') this.otherFormTarget.submit()
        }
    }

    toggleOtherSubmit() {
        if (this.otherFormTarget && this.toggleReady) {
            this.toggleReady = false
            if (this.otherButton) {
                this.otherButton.disabled = !this.otherButton.disabled;
                // the bootstrap collapse speed is set to 0.35s, so we take 350 ms as timeout
                setTimeout(() => {
                    this.toggleReady = true
                }, 350);
            }
        }
    }

    _syncDisabled() {
        if (this.ownFormTarget && this.toggleButtonTarget) {
            this.toggleButtonTarget.disabled = this.ownButton.disabled;
        }
    }
}
