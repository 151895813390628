// app/javascript/controllers/timeout_modal_controller.js
import {Controller} from "@hotwired/stimulus"
import {Modal} from "bootstrap"

export default class extends Controller {
    static values = {
        resourceType: String,
        resourceId: Number,
        lockTimeInSeconds: Number,
        redirectPath: String,
        extendLockPath: String,
        editLockEnabled: Boolean
    }

    connect() {
        console.log("TimeoutModalController connected.");
        // trigger automatic extension of edit time, if user enters site with little lockTime left
        if (this.editLockEnabledValue && this.lockTimeInSecondsValue <= 65) {
            this.extendLockAndHideModal();
        }
    }

    disconnect() {
        console.log("TimeoutModalController disconnected.");
        this.clearCountdown();
    }

    /*
    * Stimulus callback
    * Entering edit sites set a lockTimeInSecondsValue, which should result in a new countdown
    */
    lockTimeInSecondsValueChanged(newValue, oldValue) {
        if (this.lockTimeInSecondsValue > 65) {
            console.log(`Lock time changed from ${oldValue} to ${newValue}.`);
            this.restartCountdown();
        }
    }

    // response triggers callback lockTimeInSecondsValueChanged
    extendLockAndHideModal() {
        Rails.ajax({
            type: "GET",
            url: this.extendLockPathValue,
            dataType: "script",
            success: () => {
                console.log("Lock automatically extended.");

                const modalElement = document.getElementById('timeoutModal');
                if (modalElement) {
                    const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
                    this.removeModal(modal, modalElement);
                }
            },
            error: (err) => {
                console.log("Failed to extend lock:", err);
                this.showModal();
            }
        });
    }

    restartCountdown() {
        this.clearCountdown();
        this.startCountdown();
        console.log("Countdown restarted.");
    }

    clearCountdown() {
        clearTimeout(this.countdownTimeout);
        this.countdownTimeout = null;
        console.log('Countdown cleared.');
    }

    startCountdown() {
        const lockTimeInMilliseconds = (this.lockTimeInSecondsValue - 65) * 1000;

        this.countdownTimeout = setTimeout(() => {
            this.handleTimeout();
        }, lockTimeInMilliseconds);

        console.log(`Countdown started for ${this.lockTimeInSecondsValue} seconds.`);
    }

    removeModal(modal, modalElement) {
        modalElement.addEventListener('hidden.bs.modal', () => {
            modalElement.remove();

            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                modalBackdrop.remove();
            }
            document.body.style.overflow = '';
        }, {once: true});

        modal.hide();
    }

    showModal() {
        const template = document.getElementById('timeoutModalTemplate');
        const modalClone = template.content.cloneNode(true);
        document.body.appendChild(modalClone);

        const modalElement = document.getElementById('timeoutModal');
        const modal = new Modal(modalElement);
        modal.show();

        this.addExtendLockListener(modal, modalElement);
        this.addFormSubmissionListener(modal, modalElement);
    }

    handleTimeout() {
        if (this.editLockEnabledValue === false) {
            this.extendLockAndHideModal();
        }
        else if (this.uploadIsInProgress()) {
            console.log("Upload in progress. Extending lock automatically.");
            this.extendLockAndHideModal();
        } else {
            console.log("Showing timeout modal.");
            this.showModal();
        }
    }

    uploadIsInProgress() {
        return document.querySelector('.is-uploading') !== null;
    }

    addExtendLockListener(modal, modalElement) {
        const extendEditLink = modalElement.querySelector('#timeoutContinueButton');
        extendEditLink.addEventListener('click', (event) => {
            this.extendLockAndHideModal();
        })
    }

    addFormSubmissionListener(modal, modalElement) {
        const confirmButton = modalElement.querySelector('#timeoutConfirmButton');
        confirmButton.addEventListener('click', () => {
            this.removeModal(modal, modalElement);
            window.onbeforeunload = null;
            this.saveAndRedirect();
        });
    }

    saveAndRedirect() {
        const autoSaveFlags = document.querySelectorAll('.auto_save_flag');
        if (autoSaveFlags.length > 0) {
            autoSaveFlags.forEach((flag) => {
                flag.value = 'true';
            });
            this.saveChanges();
        } else {
            window.location.href = this.redirectPathValue;
        }

        setTimeout(() => {
            console.log("Fallback redirect.")
            this.fallbackRedirect();
        }, 3500);
    }

    saveChanges() {
        const selector = 'button[name="button"][type="submit"]:not([data-triggers-external-api])'
        const submitButtons = document.querySelectorAll(selector);
        console.log(`Triggering submit on ${submitButtons.length} forms.`);
        if (submitButtons.length > 0) {
            submitButtons.forEach((button) => button.click());
        }
    }

    // useful to ensure redirect, even if signal was not received from server
    fallbackRedirect() {
        document.querySelectorAll('[data-controller~="form-leave"]').forEach(el => {
            el.dataset.preventLeave = "false";
        });

        document.querySelectorAll('form').forEach(form => form.remove());
        window.location.href = this.redirectPathValue;
    }
}
