// controllers/auto_redirect_controller.js
import {Controller} from "@hotwired/stimulus"
import {Modal} from "bootstrap"

export default class extends Controller {
    static values = {
        delay: Number,
        redirectPath: String
    }

    connect() {
        this.modal = new Modal(this.element);
        this.modal.show();
        this.timeoutId = setTimeout(() => {
            this.autoSaveAndRedirect();
        }, this.delayValue);
    }

    disconnect() {
        clearTimeout(this.timeoutId);
    }

    autoSaveAndRedirect() {
        const autoSaveFlags = document.querySelectorAll('.auto_save_flag');
        if (autoSaveFlags.length > 0) {
            autoSaveFlags.forEach((flag) => {
                flag.value = 'true';
            });
            this.saveChanges();
        } else {
            window.location.href = this.redirectPathValue;
        }

        setTimeout(() => {
            console.log("Fallback redirect.")
            this.fallbackRedirect();
        }, 3500);
    }

    saveChanges() {
        const selector = 'button[name="button"][type="submit"]:not([data-triggers-external-api])'
        const submitButtons = document.querySelectorAll(selector);
        console.log(`Triggering submit on ${submitButtons.length} forms.`);
        if (submitButtons.length > 0) {
            submitButtons.forEach((button) => button.click());
        }
    }

    // useful to ensure redirect, even if signal was not received from server
    fallbackRedirect() {
        document.querySelectorAll('[data-controller~="form-leave"]').forEach(el => {
            el.dataset.preventLeave = "false";
        });

        document.querySelectorAll('form').forEach(form => form.remove());
        window.location.href = this.redirectPathValue;
    }
}
