import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        userId: Number,
        resourceType: String,
        cssSelectorPrefix: String,
        noSearchFlag: {type: Boolean, default: false}
    };

    connect() {
        this.subscription = window.App.cable.subscriptions.create(
            {channel: "ResourceLockChannel"},
            {
                initialized: () => console.log("ResourceLockChannel Initialized"),
                connected: () => console.log("Connected to ResourceLockChannel"),
                disconnected: () => console.log("Disconnected from ResourceLockChannel"),
                received: (data) => this.handleReceivedData(data)
            }
        );
    }

    disconnect() {
        if (this.subscription) {
            window.App.cable.subscriptions.remove(this.subscription);
        }
    }

    handleReceivedData(data) {
        console.log("Received data from ResourceLockChannel:", data);

        if (!this.isValidResourceType(data.resource_type)) return;

        if (data.action === "released") {
            this.handleReleaseAction(data);
        } else if (data.action === "locked") {
            this.handleLockAction(data);
        }
    }

    isValidResourceType(receivedType) {
        return receivedType === this.resourceTypeValue;
    }

    getHtmlElement(resourceId) {
        const selector = `${this.cssSelectorPrefixValue}${resourceId}`;
        return document.querySelector(selector);
    }

    updateLockIcon(htmlElement, lockHtml) {
        let lockIconContainer;
        if (htmlElement.classList.contains('lock-icon-container')) {
            lockIconContainer = this.element;
        } else {
            lockIconContainer = htmlElement.querySelector(".lock-icon-container");
        }
        lockIconContainer.innerHTML = lockHtml;
    }

    disableListItems() {
        document.querySelectorAll("[data-disable-target='true']").forEach(target => {
            target.classList.add("disabled");
        });
    }

    enableListItems() {
        document.querySelectorAll("[data-disable-target='true']").forEach(target => {
            target.classList.remove("disabled");
        });
    }

    handleReleaseAction(data) {
        if (this.noSearchFlagValue) {
            console.log("flag:" + this.noSearchFlagValue);

            this.updateLockIcon(this.element, data.lock_icon_html);
            this.enableListItems();
            return;
        }

        const htmlElement = this.getHtmlElement(data.resource_id);
        this.updateLockIcon(htmlElement, data.lock_icon_html);
        this.enableListItems();
    }

    handleLockAction(data) {
        if (this.noSearchFlagValue) {
            const lockHtml = data.user_id === this.userIdValue ? data.owner_lock_icon_html : data.others_lock_icon_html;
            this.updateLockIcon(this.element, lockHtml);
            this.disableListItems();
            return;
        }

        const htmlElement = this.getHtmlElement(data.resource_id);
        const isThisUser = (data.user_id === this.userIdValue);
        const lockHtml = isThisUser ? data.owner_lock_icon_html : data.others_lock_icon_html;

        this.updateLockIcon(htmlElement, lockHtml);

        if (!isThisUser) {
            this.disableListItems();
        }
    }
}
